@import '@contentful/forma-36-tokens/dist/scss/index.scss';

html,
body,
div {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.app {
  .title {
    margin: 0 auto;
    margin-top: var(--spacing-xl);
    max-width: 768px;
    position: relative;
    h1,
    p {
      color: #fff;
      margin: 0;
    }

    p {
      opacity: 0.8;
    }

    .note {
      margin-top: var(--spacing-m);
    }
  }

  .unsaved {
    $color: var(--color-text-dark);
    position: fixed;
    text-align: center;
    top: var(--spacing-m);
    right: var(--spacing-l);
    padding: var(--spacing-s);
    background-color: $color;
    border-radius: 4px;

    transform: scale(0.5);
    opacity: 0;
    transition: all 0.2s var(--transition-easing-cubic-bezier);

    p {
      color: white;
      svg {
        vertical-align: sub;
        margin-right: var(--spacing-xs);
      }
    }

    &.shown {
      opacity: 1;
      transform: scale(1);
    }
  }

  .logo img {
    width: 45px;
    height: 45px;
  }

  .body {
    height: auto;
    min-height: 65vh;
    margin: 0 auto;
    margin-top: var(--spacing-l);
    max-width: 768px;
    background-color: white;
    z-index: 2;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    box-sizing: border-box;

    &.pre-install {
      padding: 0;
      min-height: auto;

      input {
        vertical-align: text-bottom;
      }

      .info {
        padding: var(--spacing-xl);
        display: flex;

        .text {
          width: 55%;

          .list {
            color: var(--color-text-mid);
          }
        }

        .preview {
          width: 45%;
          position: relative;
        }
      }

      .eap-check {
        background-color: var(--color-element-lightest);
        border-radius: 0 0 4px 4px;
        border-right: none;
        border-left: none;
        border-bottom: none;

        label {
          margin-bottom: 0;
          button {
            font-weight: 600;
          }
        }
      }
    }
  }

  .background {
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    width: 100%;
    height: 300px;
    background: #327fea;
  }

  .logo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: var(--spacing-xl);
    margin-bottom: var(--spacing-xl);

    img {
      border: 1px solid var(--color-element-mid);
      border-radius: 4px;
    }
  }
}

.dialog {
  padding: var(--spacing-l);
}

.workflow-flow {
  background-color: var(--color-element-lightest);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-l);
  border-radius: 0;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  box-shadow: none;

  .states {
    position: relative;
    min-height: var(--spacing-l);
    width: 100%;
    z-index: 1;

    .line {
      $lineWidth: 2px;
      height: calc(100% + var(--spacing-m));
      width: $lineWidth;
      background-color: var(--color-element-dark);
      position: absolute;
      left: calc(50% - #{$lineWidth});
      bottom: 0;
      z-index: -1;
    }
  }

  .entry-created {
    border: 1px solid var(--color-element-mid);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--spacing-xs);
    width: 100%;
    box-sizing: border-box;
    margin-bottom: var(--spacing-m);

    p {
      margin: 0;
      color: var(--color-text-lightest);
    }
  }

  .add-button {
    margin-bottom: var(--spacing-m);
  }
}

.workflow-meta {
  padding: var(--spacing-l);

  .content-types {
    margin-top: var(--spacing-l);
  }
}

.eap-warning {
  margin: inherit;
  max-width: fit-content;

  @media screen and (min-width: 700px) {
    margin: var(--spacing-m) auto;
    padding: var(--spacing-m);
    max-width: 700px;
  }
}
