.modify-dialog {
  .color-picker {
    margin-right: var(--spacing-s);
    height: 32px;
    width: 32px;

    button {
      width: 100%;
      height: 100%;
    }

    div {
      $colorSizeSquare: 12px;
      width: $colorSizeSquare;
      height: $colorSizeSquare;
    }
  }

  .input-section {
    margin: var(--spacing-l) 0;

    &:first-child {
      margin-top: 0;
      margin-bottom: var(--spacing-s);
    }
  }

  .button-section {
    button {
      margin-right: var(--spacing-m);
    }
  }
}
